<!--
* @Description 联系我们
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top"></div>
    <div class="con">
      <h1 class="aboutTitle" style="padding-bottom: 24px">{{ $t("about.section1_title") }}</h1>
      <div class="con_info text-align-center">{{ $t("about.section1_title2") }}</div>
      <div class="title">{{ $t("about.section1_text") }}</div>
      <div class="subTitle">{{ $t("about.section1_text2") }}</div>
      <el-form
        class="formClass"
        ref="form"
        :label-position="'top'"
        label-width="120px"
        :model="formData"
        :rules="rules"
        :hide-required-asterisk="true"
      >
        <div class="flex-space-between2">
          <el-form-item :label="$t(`about.section1_from1`)" prop="userName">
            <el-input v-model="formData.userName"></el-input>
          </el-form-item>
          <el-form-item :label="$t(`about.section1_from2`)" prop="userPhone">
            <el-input v-model="formData.userPhone"></el-input>
          </el-form-item>
          <el-form-item :label="$t(`about.section1_from3`)" prop="userEmail">
            <el-input v-model="formData.userEmail"></el-input>
          </el-form-item>
        </div>
        <el-form-item :label="$t(`about.section1_from4`)" prop="userDemand">
          <el-input type="textarea" v-model="formData.userDemand"></el-input>
        </el-form-item>

        <div class="submitBtn" @click="submit">{{ $t(`about.section1_submit1`) }}</div>
      </el-form>

      <h1 class="aboutTitle">{{ $t(`about.section2_title`) }}</h1>
      <div class="content">
        <el-row :gutter="24">
          <el-col :span="13">
            <div class="title">{{ $t(`about.section2_item1_title`) }}</div>
            <div class="subTitle">{{ $t(`about.section2_item2_text`) }}</div>
            <div class="flex-wrap isName">
              <span>{{ $t(`about.section2_item1_phone1`) }}</span>
              <span>{{ $t(`about.section2_item1_phone2`) }}</span>
            </div>
          </el-col>
          <el-col :span="11">
            <div class="title">{{ $t(`about.section2_item2_title`) }}</div>
            <div class="subTitle">{{ $t(`about.section2_item2_text`) }}</div>
            <div class="flex-wrap isName">
              <span>{{ $t(`about.section2_item2_phone1`) }}</span>
              <span>{{ $t(`about.section2_item2_phone2`) }}</span>
              <span>{{ $t(`about.section2_item2_phone3`) }}</span>
            </div>
          </el-col>
        </el-row>
      </div>

      <h1 class="aboutTitle">{{ $t(`about.section3_title`) }}</h1>
      <div class="subAboutTitle">
        {{ $t(`about.section3_content1`) }}
        <br/>
        {{ $t(`about.section3_content2`) }}
      </div>
      <img src="../../../assets/images/aboutImg/about.png" alt="" />
    </div>
  </div>
</template>

<script>
import { userInsertApi } from "@/api/about.js";
export default {
  name: "index",
  components: {},
  data() {
    return {
      formData: {
        userName: null,
        userPhone: null,
        userEmail: null,
        userDemand: null,
      },

      // 表单校验
      rules: {
        userName: [
          { required: true, message: this.$t("about.from_rule1"), trigger: "change" },
        ],
        userPhone: [
          { required: true, message: this.$t("about.from_rule2"), trigger: "blur" },
        ],
        userEmail: [
          { required: true, message: this.$t("about.from_rule3"), trigger: "blur" },
          {
            type: "email",
            message: this.$t("about.from_rule4"),
            trigger: "blur",
          },
        ],
        userDemand: [
          { required: true, message: this.$t("about.from_rule5"), trigger: "change" },
        ],
      },
    };
  },
  computed: {
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          userInsertApi(this.formData).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: this.$t("about.submit_tips"),
                type: "success",
                duration: 2000,
              });
              this.formData = {
                userName: null,
                userPhone: null,
                userEmail: null,
                userDemand: null,
              };
              this.$refs["form"].resetFields();
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
::v-deep .el-form-item__label {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #301d09;
  line-height: 18px;
  padding-bottom: 8px;
}
::v-deep .el-form-item {
  margin-bottom: 24px;
}
::v-deep .el-form-item__content {
  line-height: 24px;
}
::v-deep .el-input__inner {
  width: 300px;
  height: 24px;
  background: #f5f5f5;
  border: none;
  font-weight: 400;
  color: #301d09;
}

::v-deep .el-textarea__inner {
  width: 948px;
  height: 120px;
  background: #f5f5f5;
  border: none;
  font-weight: 400;
  color: #301d09;
}
</style>
<style lang="scss" scoped>
.aboutContainer {
  background: #fff;
  .top {
    width: 100%;
    height: 400px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/aboutImg/B0002507@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .con {
    width: 948px;
    margin: 0 auto;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #301d09;
    padding-bottom: 24px;
    img {
      width: 100%;
      height: 400px;
    }

    .aboutTitle {
      text-align: center;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      padding-top: 40px;
    }
    .con_info{
      font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #301d09;
    margin-top: -10px;
    margin-bottom: 30px;
    }
    .subAboutTitle {
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: rgba(48, 29, 9, 0.74);
      padding: 24px 0;
      line-height: 1.6;
    }

    .title {
      font-size: 24px;
      font-weight: 400;
      color: #301d09;
    }
    .subTitle {
      font-size: 18px;
      font-weight: 400;
      color: rgba(48, 29, 9, 0.6);
    }

    .formClass {
      padding-top: 24px;
    }

    .submitBtn {
      width: 100%;
      height: 24px;
      line-height: 24px;
      background: #333333;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }

    .content {
      margin-top: 55px;
      font-size: 18px;
      font-weight: 400;
      color: #301d09;

      .isName {
        margin-top: 20px;
        line-height: 1.5;
        span {
          padding-right: 40px;
          // 每行最右侧的那个不设置右外边距
          &:nth-child(2n + 2) {
            padding-right: 0;
          }
        }
      }
    }
  }
}
</style>